import React from "react";

import Windy from "./svg/Windy";
import Klimatyzacja from "./svg/Klimatyzacja";
import Beton from "./svg/Beton";
import Hydranty from "./svg/Hydranty";
import LuxAwaria from "./svg/LuxAwaria";
import Detectors from "./svg/Detectors";
import Internet from "./svg/Internet";
import Hala from "./svg/10m";
// import FloorBox from "./svg/FloorBox"
// import Walls from "./svg/Walls"
// import Reception from "./svg/Reception"
// import BMS from "./svg/BMS"
// import Access from "./svg/Access"
import Lux from "./svg/Lux";
import Teletech from "./svg/Teletech";
import Heating from "./svg/Heating";
import Szlaban from "./svg/Szlaban";
import ATM from "./svg/ATM";

export const primary = "rgba(189, 195, 199, 1)";
export const success = "#ff5722";
export const fontColor = "#002d73";
export const seaBlue = "#00acc1";

export const icons = [
  <Szlaban />,
  <Heating />,
  <Teletech />,
  <Lux />,
  <Klimatyzacja />,
  <Beton />,
  <Hydranty />,
  <LuxAwaria />,
  <Detectors />,
  <Windy />,
  <Internet />,
  <Hala />,
];
