import React, { Fragment } from "react"
import styled from "styled-components"

const goUpButton = ({ propertyName }) => {
  const goTopHandler = e => {
    e.preventDefault()
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  return (
    <Fragment>
      <StyledLogo onClick={goTopHandler} href="/">
        <SpanDesktop>{propertyName}</SpanDesktop>
        <SpanMobile>Eurologis I / II</SpanMobile>
      </StyledLogo>
    </Fragment>
  )
}

export default goUpButton

const StyledLogo = styled.a`
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 3.39;
  letter-spacing: 3.27px;
  color: #fff;
  text-decoration: none;
`

const SpanDesktop = styled.span`
display : none;
 @media screen and (min-width: 576px) {
    display : block;
  }
`

const SpanMobile = styled.span`
display : block;
 @media screen and (min-width: 576px) {
    display : none;
  }
`