import React from "react";
import styled from "styled-components";

interface NavigationProps {
  titles: Array<string>;
  lngChangeHandler(): void;
  lang: string;
}

const Navigation: React.FC<NavigationProps> = ({
  titles,
  lang,
  lngChangeHandler,
}) => {
  const scrollHandler = (event) => {
    event.preventDefault();
    let offset =
      document.getElementById(event.target.innerText.toUpperCase()).offsetTop -
      85;
    if (offset) window.scrollTo({ top: offset, left: 0, behavior: "smooth" });
  };

  let language;
  lang === "PL" ? (language = "EN") : (language = "PL");

  const naviLinks = titles.map((title, index) => {
    return (
      <li key={index}>
        <StyledLngLink onClick={scrollHandler} href={"/"}>
          {title}
        </StyledLngLink>
      </li>
    );
  });

  return (
    <StyledNav>
      <StyledUl>
        {naviLinks}
        <li>
          <StyledLngLink href="/" onClick={lngChangeHandler}>
            {language}
          </StyledLngLink>
        </li>
      </StyledUl>
    </StyledNav>
  );
};

export default Navigation;

const StyledNav = styled.nav`
  display: flex;
  font-size: 1rem;
`;

export const StyledLngLink = styled.a`
  font-family: Montserrat;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.23px;
  text-align: center;
  color: #ffffff;

  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.2s ease;
  padding: 10px 0;
  margin: 0 10px;
  &:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 0;
    background: #fff;
    left: 0;
    bottom: 5px;
    transition: 0.2s ease;
  }
  &:hover {
    &:after {
      width: 100%;
    }
  }
`;

const StyledUl = styled.ul`
  display: none;
  list-style-type: none;
  align-items: center;
  margin: 0;
  @media screen and (min-width: 992px) {
    display: flex;
  }
  li {
    display: inline-block;
    margin: 0 8px;
    @media only screen and (max-width: 1365.99px) and (min-width: 1025px) {
      margin: 0 4px;
    }
  }
`;
