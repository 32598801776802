import React from "react"

const Windy = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.52 35.52">
      <g data-name="Warstwa 2">
        <g data-name="Warstwa 1">
          <path d="M33.69,0H1.82A1.83,1.83,0,0,0,0,1.82V33.69a1.83,1.83,0,0,0,1.82,1.83h2.8a.4.4,0,0,0,0-.8H1.82a1,1,0,0,1-1-1V1.82a1,1,0,0,1,1-1H33.69a1,1,0,0,1,1,1V33.69a1,1,0,0,1-1,1h-3a.4.4,0,0,0,0,.8h3a1.83,1.83,0,0,0,1.83-1.83V1.82A1.83,1.83,0,0,0,33.69,0Z" />
          <path d="M27.08,7.77H8.44A2.48,2.48,0,0,0,6,10.25V32.69a.4.4,0,1,0,.8,0V10.25A1.68,1.68,0,0,1,8.44,8.57H27.08a1.68,1.68,0,0,1,1.68,1.68V32.69a.4.4,0,0,0,.8,0V10.25A2.48,2.48,0,0,0,27.08,7.77Z" />
          <path d="M25.86,10H9.66a1.43,1.43,0,0,0-1.43,1.42V35.52H27.29V11.39A1.43,1.43,0,0,0,25.86,10Zm.62,24.75H9V11.39a.63.63,0,0,1,.63-.62h16.2a.62.62,0,0,1,.62.62Z" />
          <path d="M17.76,12.91a.4.4,0,0,0-.4.4V32.18a.4.4,0,1,0,.8,0V13.31A.4.4,0,0,0,17.76,12.91Z" />
          <path d="M15.55,5.3a.35.35,0,0,0,.26-.1l.74-.74A.37.37,0,0,0,16,3.94l-.48.48-.47-.48a.37.37,0,0,0-.52.52l.73.74A.39.39,0,0,0,15.55,5.3Z" />
          <path d="M19.49,5.2,20,4.72l.48.48a.35.35,0,0,0,.26.1A.37.37,0,0,0,21,5.2a.37.37,0,0,0,0-.52l-.74-.74a.37.37,0,0,0-.52,0L19,4.68a.36.36,0,0,0,0,.52A.37.37,0,0,0,19.49,5.2Z" />
        </g>
      </g>
    </svg>
  )
}

export default Windy