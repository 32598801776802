import React from "react"

const Szlaban = () => {
  return (
    <svg
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
    >
      <title>ico_</title>
      <circle cx="9.9" cy="12.16" r="0.38" fill="none" stroke="#010101" />
      <rect
        x="3.99"
        y="35.76"
        width="11.81"
        height="3.37"
        rx="1.69"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M7,33.83V12.08A2.68,2.68,0,0,1,9.68,9.41h3.1V34"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M15.09,9.41H38.53A2.52,2.52,0,0,1,41,11.92h0a2.52,2.52,0,0,1-2.51,2.5H15.05"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="15.39"
        y1="12.8"
        x2="17.27"
        y2="10.93"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="17.25"
        y1="14.35"
        x2="22.03"
        y2="9.57"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="21.91"
        y1="12.8"
        x2="23.79"
        y2="10.93"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="23.77"
        y1="14.35"
        x2="28.55"
        y2="9.57"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="28.43"
        y1="12.8"
        x2="30.31"
        y2="10.93"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="30.29"
        y1="14.35"
        x2="35.07"
        y2="9.57"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="34.95"
        y1="12.8"
        x2="36.83"
        y2="10.93"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="36.81"
        y1="14.35"
        x2="40.42"
        y2="10.74"
        fill="none"
        stroke="#010101"
      />
    </svg>
  )
}

export default Szlaban
