import React from "react"

const Internet = () => {
  return (
    <svg
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
    >
      <title>ico_</title>
      <circle cx="22.5" cy="31.95" r="3.07" fill="none" stroke="#010101" />
      <path
        d="M27.33,23.84a12.81,12.81,0,0,1,3,1.77"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M14.64,25.61a12.79,12.79,0,0,1,7.77-2.73,12.44,12.44,0,0,1,2.34.2"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M15.74,17.29a20,20,0,0,1,6.61-1.18A20.38,20.38,0,0,1,35,20.45"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M10,20.45a20.39,20.39,0,0,1,2.28-1.55"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M29.26,10.78a30,30,0,0,1,11.52,5.56"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M4.22,16.34A29.77,29.77,0,0,1,22.28,10c.6,0,1.19,0,1.77,0"
        fill="none"
        stroke="#010101"
      />
    </svg>
  )
}

export default Internet
