import React from "react"

const Hala = () => {
  return (
    <svg
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
    >
      <title>ico_</title>
      <line
        x1="17.12"
        y1="4.03"
        x2="27.88"
        y2="4.03"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="17.12"
        y1="41.41"
        x2="27.88"
        y2="41.41"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="17.12"
        y1="22.5"
        x2="27.88"
        y2="22.5"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="22.5"
        y1="8.87"
        x2="22.5"
        y2="17.73"
        fill="none"
        stroke="#010101"
      />
      <polyline
        points="16.17 14.51 22.53 8.14 28.83 14.45"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="22.5"
        y1="36.13"
        x2="22.5"
        y2="27.27"
        fill="none"
        stroke="#010101"
      />
      <polyline
        points="16.17 30.5 22.53 36.86 28.83 30.55"
        fill="none"
        stroke="#010101"
      />
    </svg>
  )
}

export default Hala
