import React from 'react'
import styled from 'styled-components'
import { StyledContainer } from '../pages/index.js'
import m7Logo from '../data/images/m7-logo.svg'
import blazityLogo from '../data/svg//blazity-logo.svg'
import linkedIn from '../data/svg/linkedin-brands.svg'
import website from '../data/svg/website.svg'
import { fontColor } from './variables'
import Chevron from './svg/Chevron.js'
import Img from 'gatsby-image'

interface FooterProps {
  contactData: {
    contactPersons: Array<any>
    companyName: string
    street: string
    postCode: string
    phoneNumber: string
    email: string
    websiteAdress: string
    credits: string
  }
  data: {
    sectionTitle: string
    companyDescription: string
    AdresSectionSubtitle: string
    city: string
    locationsTitle: string
  }
  footerLogo: any
}

const otherLocations = [
  {
    name: 'Cristal Park',
    url: 'https://cristal-park.pl/',
  },
  {
    name: 'Delta 44',
    url: 'https://delta44.pl/',
  },
  {
    name: 'Długosza Business Park',
    url: 'https://dlugoszabp.pl/',
  },
  {
    name: 'Green Park',
    url: 'https://greenparkkatowice.pl',
  },
  {
    name: 'Nowa Kamienica',
    url: 'https://nowakamienica.eu',
  },
  {
    name: 'Atrium',
    url: 'https://atriumkatowice.pl/',
  },
  {
    name: 'M7 Logistics',
    url: 'https://m7logistics.pl/',
  },
  {
    name: 'Mogileńska 50',
    url: 'http://mogilenska50.pl/',
  },
]

const Footer: React.FC<FooterProps> = ({ data, contactData, lang, footerLogo }) => {
  const person = contactData.contactPersons.map((person, index) => {
    return (
      <StyledCell key={index}>
        <StyledName>{person.name}</StyledName>
        <p>{person.position}</p>
        <p>{person.phoneNumber}</p>
        <p>{person.email}</p>
      </StyledCell>
    )
  })

  const locations = otherLocations.map((property, index) => {
    return (
      <StyledLocation rel="noopener" target="_blank" href={property.url} key={index}>
        <p style={{ marginRight: '10px', marginTop: '-6px' }}>{property.name}</p>
        <Chevron ovalSize="14px" arrowSize="14px" backgroundColor="transparent" />
      </StyledLocation>
    )
  })

  return (
    <StyledFooterSection id={`${data.sectionTitle.toUpperCase()}`}>
      <StyledContainer>
        <StyledFooterWrapper>
          <StyledLogoColumn>
            <div style={{ width: '100%' }}>
              <StyledFooterLogo>
                <StyledLogoImg fluid={footerLogo.childImageSharp.fluid} alt="martleycapital" imgStyle={{ objectFit: 'contain' }} />
              </StyledFooterLogo>
            </div>
          </StyledLogoColumn>
          <StyledCompanyInfoDiv>
            <StyledCompanyAdressData>{contactData.companyName}</StyledCompanyAdressData>
            <StyledCompanyAdressData>{contactData.street}</StyledCompanyAdressData>
            <StyledCompanyAdressData>
              {contactData.postcode}
              <span style={{ marginLeft: '10px' }}>{data.city}</span>
            </StyledCompanyAdressData>
            <StyledWebsiteLink href="https://www.martleycapital.com" target="_blank">
              www.martleycapital.com
            </StyledWebsiteLink>
          </StyledCompanyInfoDiv>
          <StyledContactColumn>
            <StyledTitleDiv>
              <StyledTitle>{data.sectionTitle}</StyledTitle>
              <StyledLink href="https://uk.linkedin.com/company/martley-capital-group/">
                <StyledImg src={linkedIn} alt="linkedin"></StyledImg>
              </StyledLink>
            </StyledTitleDiv>
            <StyledPeopleRow>{person}</StyledPeopleRow>
          </StyledContactColumn>
        </StyledFooterWrapper>
      </StyledContainer>
      <StyledBrighterFooterSection>
        <StyledContainer>
          <StyledLocations>
            <StyledLocationsTitle>{data.locationsTitle}</StyledLocationsTitle>
            <StyledLocationsNames>{locations}</StyledLocationsNames>
          </StyledLocations>
        </StyledContainer>
      </StyledBrighterFooterSection>
      <StyledContainer>
        <StyledCredits>
          <p>
            {contactData.credits} {new Date().getFullYear()}
          </p>
          <BlazityLogo rel="noopener" target="_blank" href="https://blazity.com/">
            {lang === 'PL' ? <p>Realizacja</p> : <p>Implementation</p>}
            <img src={blazityLogo} alt="blazity" />
          </BlazityLogo>
        </StyledCredits>
      </StyledContainer>
    </StyledFooterSection>
  )
}

export default Footer

const StyledFooterWrapper = styled.div`
  display: grid;
  padding-top: 80px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 24px;
  @media screen and (max-width: 900px) {
    padding-top: 50px;
    grid-template-columns: 1fr;
  }
`

const StyledCompanyAdressData = styled.p`
  color: #fff;
  line-height: 1.69;
`

const StyledDisclaimer = styled.p`
  padding: 20px 0;
  font-size: 10px;
  color: #fff;
  @media screen and (max-width: 576px) {
    font-size: 9px;
    padding: 10px 0;
  }
`

const StyledCompanyInfoDiv = styled.div`
  min-width: 200px;
  margin-top: 0;
  @media screen and (max-width: 900px) {
    margin-top: 0;
    p {
      text-align: center;
    }
  }
`

const StyledImg = styled.img`
  width: 36px;
  height: 36px;
`

const StyledCell = styled.div`
  display: flex;
  flex-direction: column;
  height: 115px;
  opacity: 0.8;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: 0.23px;
  color: #ffffff;
  margin-right: 70px;
  @media screen and (max-width: 900px) {
    margin-right: 0;
  }
  p {
    @media screen and (max-width: 900px) {
      text-align: center;
    }
  }
`

const StyledLogoColumn = styled.div`
  margin-right: 100px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 900px) {
    margin-right: 0;
    width: 100%;
    flex-direction: column !important;
  }
  @media screen and (max-width: 1366px) {
    flex-direction: row;
    margin-right: 0;
    justify-content: space-between;
  }
`
const StyledFooterLogo = styled.div`
  margin-bottom: 40px;
  padding-right: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 900px) {
    padding-right: 0px;
  }
`

const StyledCompanyDescription = styled.div`
  width: 280px;

  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: 0.23px;
  color: #ffffff;
  margin-bottom: 30px;
  @media screen and (max-width: 1366px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 900px) {
    width: auto;
    height: auto;
    text-align: center;
  }
`

const StyledContactColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
`

const StyledTitle = styled.h3`
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.3px;
  color: #ffffff;
  padding: 0;
  margin: 0 0 50px;
  @media screen and (max-width: 1366px) {
    margin: 0 0 20px;
  }
  @media screen and (max-width: 1024px) {
    margin: 0 0 20px;
  }
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`

const StyledPeopleRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 900px) {
    justify-content: center;
  }
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #fff;
`

const StyledLocations = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
`

const StyledLocationsTitle = styled.h3`
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.3px;
  color: #ffffff;
  padding: 0;
  margin: 0 0 35px;
`

const StyledLocationsNames = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  a {
    margin-right: 30px;
    margin-bottom: 10px;
    white-space: nowrap;
  }
`

const StyledLocation = styled.a`
  text-decoration: none;
  font-size: 19px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.3px;
  color: #fff;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
`

const StyledCredits = styled.div`
  width: 100%;
  padding: 30px 0;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: 0.23px;
  color: #c3c3c3;
  display: flex;
  @media screen and (max-width: 505px) {
    flex-direction: column;
    align-items: center;
  }
  justify-content: space-between;
  p {
    padding-top: 6px;
    margin-right: 7px;
  }
`

const StyledName = styled.p`
  margin: 0;
  font-family: OpenSans-SemiBold;
  text-decoration: underline;
`

const BlazityLogo = styled.a`
  display: flex;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: 0.23px;
  align-items: center;
  color: #e1e1e1;
  text-decoration: none;
  p {
    padding-top: 8px;
    margin-right: 5px;
  }
  img {
    width: 68px;
    height: 22px;
  }
`

const StyledTitleDiv = styled.div`
  position: relative;
`

const StyledLink = styled.a`
  position: absolute;
  top: 0;
  right: 0;
`

const StyledFooterSection = styled.div`
  background-color: ${fontColor};
`

const StyledBrighterFooterSection = styled.div`
  padding: 1px 0;
  background-color: #1c4484;
`

const StyledWebsiteLink = styled.a`
  text-decoration: none;
  color: #fff;
  display: inline-block;
  width: 100%;
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`

const StyledLogoImg = styled(Img)`
  width: 100%;
  max-width: 317px;
`
