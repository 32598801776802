import React from "react"

const Heating = () => {
  return (
    <svg
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
    >
      <title>ico_</title>
      <path
        d="M11.1,25A28.23,28.23,0,0,1,12.77,29c.63,2.35-1.08,5.18-.61,5.31s4.51-3.88,4.67-5.66c.2-2.82-4.76-11.07-5.16-13.2-.46-2.48.36-4.65,0-4.71s-4,3-4,5.27a14.88,14.88,0,0,0,1.57,5.11"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M20.82,11.21c0-2.12.54-3.69.22-3.74s-5.15,3.78-5.15,6.71c0,4,5.57,13.06,6.51,16.57.81,3-1.37,6.61-.77,6.77.55.36,5.75-5,5.95-7.22.22-3.07-4.36-11.19-6-15.18"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M35.44,26.51C34.3,22.89,31,17.15,30.65,15.42c-.46-2.48.36-4.65,0-4.71s-4,3-4,5.27c0,3.13,4.36,10.24,5.1,13,.63,2.35-1.07,5.18-.61,5.31.26.17,1.87-1.3,3.13-2.87"
        fill="none"
        stroke="#010101"
      />
    </svg>
  )
}

export default Heating
