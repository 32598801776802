import React from "react"

const Hydranty = () => {
  return (
    <svg
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
    >
      <title>ico_</title>
      <rect
        x="12.37"
        y="15.51"
        width="20.26"
        height="24.07"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M25.63,23.18A5.5,5.5,0,1,0,28,27.71a5.43,5.43,0,0,0-.44-2.17"
        fill="none"
        stroke="#010101"
      />
      <circle cx="22.5" cy="27.71" r="2.17" fill="none" stroke="#010101" />
      <path
        d="M26.49,5.77a10,10,0,0,0-4-.82,10.13,10.13,0,0,0-9.81,7.65H32.31A10.13,10.13,0,0,0,29.4,7.68"
        fill="none"
        stroke="#010101"
      />
      <rect
        x="9.21"
        y="12.6"
        width="26.59"
        height="2.91"
        rx="1.45"
        fill="none"
        stroke="#010101"
      />
      <rect
        x="9.21"
        y="39.58"
        width="26.59"
        height="2.91"
        rx="1.45"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M10.37,24.15H8.52A1.87,1.87,0,0,0,6.65,26v3.39a1.86,1.86,0,0,0,1.86,1.86h1.86"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M34.63,24.15h1.85A1.87,1.87,0,0,1,38.35,26v3.39a1.86,1.86,0,0,1-1.86,1.86H34.63"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="15.37"
        y1="15.51"
        x2="15.37"
        y2="20.65"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="19.37"
        y1="15.51"
        x2="19.37"
        y2="17.61"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="25.63"
        y1="15.51"
        x2="25.63"
        y2="17.61"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="29.63"
        y1="15.51"
        x2="29.63"
        y2="20.65"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="15.37"
        y1="34.44"
        x2="15.37"
        y2="39.58"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="29.63"
        y1="34.44"
        x2="29.63"
        y2="39.58"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="19.37"
        y1="37.38"
        x2="19.37"
        y2="39.47"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="25.63"
        y1="37.38"
        x2="25.63"
        y2="39.47"
        fill="none"
        stroke="#010101"
      />
      <rect
        x="20.74"
        y="2.51"
        width="3.16"
        height="2.44"
        rx="1.22"
        fill="none"
        stroke="#010101"
      />
    </svg>
  )
}

export default Hydranty
