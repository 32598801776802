import React from "react"

const Beton = () => {
  return (
    <svg
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
    >
      <title>ico_</title>
      <path
        d="M3.78,27.68H41.22a0,0,0,0,1,0,0v6.46a2.28,2.28,0,0,1-2.28,2.28H6.06a2.28,2.28,0,0,1-2.28-2.28V27.68A0,0,0,0,1,3.78,27.68Z"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M32.43,21.66h2a2.57,2.57,0,0,1,1.89.83l4.86,5.19H3.78l4.92-5.2a2.59,2.59,0,0,1,1.89-.82h1.87"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="14.68"
        y1="23.96"
        x2="14.68"
        y2="8.58"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="22.5"
        y1="23.96"
        x2="22.5"
        y2="8.58"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="30.32"
        y1="23.96"
        x2="30.32"
        y2="8.58"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="11.64"
        y1="18.39"
        x2="33.36"
        y2="18.39"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="11.64"
        y1="11.94"
        x2="33.36"
        y2="11.94"
        fill="none"
        stroke="#010101"
      />
    </svg>
  )
}

export default Beton
