import React from "react"

const Lux = () => {
  return (
    <svg
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
    >
      <path
        d="M32.53,30.22V25.88a10,10,0,0,0-20,0v5.85A10.21,10.21,0,0,0,12.65,33l.21,0H30.32"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M14.28,37.55H12.86a2.3,2.3,0,0,1-2.29-2.3h0A2.3,2.3,0,0,1,12.86,33h19a2.3,2.3,0,0,1,2.29,2.29h0a2.3,2.3,0,0,1-2.29,2.3H18.88"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="9.97"
        y1="24.48"
        x2="6.17"
        y2="24.48"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="13.14"
        y1="15.41"
        x2="10.46"
        y2="12.73"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="21.8"
        y1="11.25"
        x2="21.8"
        y2="7.45"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="30.87"
        y1="14.42"
        x2="33.55"
        y2="11.74"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="35.03"
        y1="23.08"
        x2="38.83"
        y2="23.08"
        fill="none"
        stroke="#010101"
      />
    </svg>
  )
}

export default Lux
