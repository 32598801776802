import React from "react"

const LuxAwaria = () => {
  return (
    <svg
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
    >
      <title>ico_</title>
      <path
        d="M12,23.7a10.54,10.54,0,0,0,4.7,7.47,2.06,2.06,0,0,1,.93,1.72v2.37a5,5,0,0,0,4.34,5,4.84,4.84,0,0,0,5.32-4.8v-2.6a2,2,0,0,1,.92-1.71,10.54,10.54,0,1,0-13.83-15.6"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="17.67"
        y1="33.73"
        x2="27.33"
        y2="33.73"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="22.5"
        y1="33.73"
        x2="22.5"
        y2="28.15"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M26.73,23.7a4.23,4.23,0,0,1-8.46,0"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="4.48"
        y1="22.18"
        x2="8.11"
        y2="22.18"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="9.71"
        y1="9.41"
        x2="12.32"
        y2="12.02"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="22.48"
        y1="4.68"
        x2="22.48"
        y2="7.81"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="35.25"
        y1="9.41"
        x2="32.64"
        y2="12.02"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="40.06"
        y1="22.18"
        x2="36.85"
        y2="22.18"
        fill="none"
        stroke="#010101"
      />
    </svg>
  )
}

export default LuxAwaria
